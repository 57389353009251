// import logo from '../public/logo.png';
import styles from './css/App.module.css';
import Forms from './Forms';
import Header from './Header';
import Home from './Home';
import Navigation from './Navigation';
import { Route, Routes } from 'react-router-dom';
import Office from './Office';
import Photos from './Photos';
import About from './About';
import Contact from './Contact';

function App() {
  return (
    <div className={styles.app}>
      <Header />
      <Navigation />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/forms' element={<Forms />} />
        <Route path='/office' element={<Office />} />
        <Route path='/photos' element={<Photos />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
