import { ButtonGroup, Button } from '@chakra-ui/react'
import styles from "./css/Navigation.module.css"
import { Link } from 'react-router-dom';
function Navigation() {
    return (
        <ButtonGroup className={styles.navbar} width={"100%"}>
            <Link className={styles.w100} to="/"><Button className={styles.button}>Home</Button></Link>
            <Link className={styles.w100} to="/forms"><Button className={styles.button}>Forms</Button></Link>
            <Link className={styles.w100} to="/office"><Button className={styles.button}>Office Bearers</Button></Link>
            <Link className={styles.w100} to="/photos"><Button className={styles.button}>Photo Gallery</Button></Link>
            <Link className={styles.w100} to="/about"><Button className={styles.button}>About Us</Button></Link>
            <Link className={styles.w100} to="/contact"><Button className={styles.button}>Contact Us</Button></Link>
        </ButtonGroup>
    )
}
export default Navigation;