import Feeds from "./Feeds";
import ImageSlide from "./ImageSlide";

const image_list = [
        "https://media.geeksforgeeks.org/wp-content/uploads/20210208000010/1.png",
        "https://media.geeksforgeeks.org/wp-content/uploads/20210208000009/2.png",
        "https://media.geeksforgeeks.org/wp-content/uploads/20210208000008/3.png",
];

function Home() {
    return (
      <div>
        <ImageSlide images={image_list} />
        <Feeds />
      </div>
    );
  }
  
  export default Home;
  